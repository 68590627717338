.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Personalizado por Vivero Federal. Algunos estilos de Bootstrap se pisan */

/* Para el logo */
.sidebar .sidebar-brand {
    height: 8rem;
}

/* Para los colores y alguna animación */
body {
    color: #3C6255;
}

.border-left {
    border-left: 0.25rem solid #3C6255 !important;
}

.card {
    background-color: #DAE2B6;
    border: solid 1px #3C6255;
    border-radius: 3px;
}

.card-body {
    background-color: #DAE2B6;
}

.card-header {
    background-color: #DAE2B6;
    border: none;
}

#wrapper #content-wrapper {
    background-color: #FFFBE9;
}

.bg-light-yellow {
    background-color: #F4EAD5;
}

.sidebar {
    background-color: #F4EAD5;
    border-right: 2px solid #3C6255;
}

.sidebar-dark hr.sidebar-divider {
    border-top: 1px solid #3C6255;
}

.sidebar-dark .sidebar-heading {
    color: #3C6255;
}

.sidebar-dark .nav-item .nav-link {
    color: #3C6255;
}

.sidebar-dark .nav-item .nav-link:hover {
    color: #3C6255;
}

.sidebar-dark .nav-item .nav-link:visited {
    color: #3C6255;
}

.sidebar-dark .nav-item .nav-link i {
    position: relative;
    right: 0px;
    color: #3C6255;
    transition: all 0.3s;
}

.sidebar-dark .nav-item .nav-link:hover i {
    position: relative;
    color: #3C6255;
    right: 2px;
}

.sidebar-dark .nav-item .nav-link:visited i {
    color: #3C6255;
}

.table {
    color: #3C6255;
}

.table thead th {
    vertical-align: bottom;
    border-top: 2px solid #3C6255;
    border-bottom: 2px solid #3C6255;
}

.table td, .table th {
    border-top: 1px solid #3C6255;
}

tr {
    border-color: #3C6255;
}

/* Para cards con ancho del 100% */
.col-lg-6 {
    max-width: 100%;
}

/* Para título y texto de "home" */
.home-title {
    margin-block: 20px;
    margin-left: 5px;
}

.home-indication {
    margin-block: 50px;
    text-align: center;
}

/* Para título de TopBar */
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    line-height: 1;
}

/* Para botones de listado de productos */
.button-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.btn {
    margin-inline: 20px;
}

.btn-outline-secondary.focus, .btn-outline-secondary:focus {
    box-shadow: none;
}

.btn-outline-secondary {
    color: #3C6255;
    border-color: #3C6255;
}

.btn-outline-secondary:hover {
    background-color: #3C6255;
    color: #DAE2B6;
    border-color: #DAE2B6;
}

/* Para el buscador */

.alert-warning {
    margin-top: 25px;
}